import { useTranslation } from 'react-i18next'

import { ReasonType } from '@src/types/data'
import { storage } from '@src/utils/storage'

const useReasons = () => {
  const { t } = useTranslation()
  const country = storage.getCountry()

  const reasons: { [key: string]: ReasonType[] } = {
    KR: [
      {
        id: 1,
        text: t('reasons.1.text'),
        description: t('reasons.1.description'),
        links: [],
      },
      {
        id: 2,
        text: t('reasons.2.text'),
        description: t('reasons.2.description'),
        links: [
          {
            text: t('reasons.2.links.search_text'),
            url: 'search',
          },
          {
            text: t('reasons.2.links.keywords_text'),
            url: 'keywords',
          },
        ],
      },
      {
        id: 3,
        text: t('reasons.3.text'),
        description: t('reasons.3.description'),
        links: [
          {
            text: t('reasons.3.links.faq_text'),
            url: 'web/wv/faqs/slug/tips_for_selling',
          },
        ],
      },
      {
        id: 4,
        text: t('reasons.4.text'),
        description: t('reasons.4.description'),
        links: [
          {
            text: t('reasons.4.links.faq_text'),
            url: 'web/wv/faqs/slug/meet_rude',
          },
        ],
      },
      {
        id: 9,
        text: t('reasons.9.text'),
        description: t('reasons.9.description'),
        links: [
          {
            text: t('reasons.9.links.faq_text_1'),
            url: 'faqs/41',
          },
          {
            text: t('reasons.9.links.faq_text_2'),
            url: 'faqs/70',
          },
          {
            text: t('reasons.9.links.faq_text_3'),
            url: 'faqs/2311',
          },
        ],
        additionalDescription: t('reasons.9.additional_description'),
        additionalLinks: [
          {
            text: t('reasons.9.additional_links.faq_text_1'),
            url: 'web/wv/feedbacks/new?category_id=430&present=top',
          },
        ],
      },
      {
        id: 10,
        text: t('reasons.10.text'),
        description: t('reasons.10.description'),
        links: [
          {
            text: t('reasons.10.links.setting_text'),
            url: 'settings',
          },
        ],
      },
      {
        id: 5,
        text: t('reasons.5.text'),
        description: t('reasons.5.description'),
        links: [
          {
            text: t('reasons.5.links.faq_text_1'),
            url: 'faqs/171',
            onlyKR: true,
          },
          {
            text: t('reasons.5.links.faq_text_2'),
            url: 'faqs/3',
            onlyKR: true,
          },
        ],
      },
      {
        id: 6,
        text: t('reasons.6.text'),
        description: t('reasons.6.description'),
        links: [],
      },
    ],
    GB: [
      {
        id: 1,
        text: t('reasons.1.text'),
        description: t('reasons.1.description'),
        links: [],
      },
      {
        id: 2,
        text: t('reasons.2.text'),
        description: t('reasons.2.description'),
        links: [
          {
            text: t('reasons.2.links.search_text'),
            url: 'search',
          },
          {
            text: t('reasons.2.links.keywords_text'),
            url: 'keywords',
          },
        ],
      },
      {
        id: 3,
        text: t('reasons.3.text'),
        description: t('reasons.3.description'),
        links: [
          {
            text: t('reasons.3.links.faq_text'),
            url: 'web/wv/faqs/slug/tips_for_selling',
          },
        ],
      },
      {
        id: 4,
        text: t('reasons.4.text'),
        description: t('reasons.4.description'),
        links: [
          {
            text: t('reasons.4.links.faq_text'),
            url: 'web/wv/faqs/slug/meet_rude',
          },
        ],
      },
      {
        id: 9,
        text: t('reasons.9.text'),
        description: t('reasons.9.description'),
        links: [
          {
            text: t('reasons.9.links.faq_text_1'),
            url: 'faqs/15',
          },
          {
            text: t('reasons.9.links.faq_text_2'),
            url: 'faqs/168',
          },
          {
            text: t('reasons.9.links.faq_text_3'),
            url: 'faqs/167',
          },
        ],
        additionalDescription: t('reasons.9.additional_description'),
        additionalLinks: [
          {
            text: t('reasons.9.additional_links.faq_text_1'),
            url: 'web/wv/feedbacks/new?category_id=1&present=top',
          },
        ],
      },
      {
        id: 10,
        text: t('reasons.10.text'),
        description: t('reasons.10.description'),
        links: [
          {
            text: t('reasons.10.links.setting_text'),
            url: 'settings',
          },
        ],
      },
      {
        id: 5,
        text: t('reasons.5.text'),
        description: t('reasons.5.description'),
        links: [
          {
            text: t('reasons.5.links.faq_text_1'),
            url: 'faqs/171',
            onlyKR: true,
          },
          {
            text: t('reasons.5.links.faq_text_2'),
            url: 'faqs/3',
            onlyKR: true,
          },
        ],
      },
      {
        id: 6,
        text: t('reasons.6.text'),
        description: t('reasons.6.description'),
        links: [],
      },
    ],
    CA: [
      {
        id: 1,
        text: t('reasons.1.text'),
        description: t('reasons.1.description'),
        links: [],
      },
      {
        id: 2,
        text: t('reasons.2.text'),
        description: t('reasons.2.description'),
        links: [
          {
            text: t('reasons.2.links.search_text'),
            url: 'search',
          },
          {
            text: t('reasons.2.links.keywords_text'),
            url: 'keywords',
          },
        ],
      },
      {
        id: 3,
        text: t('reasons.3.text'),
        description: t('reasons.3.description'),
        links: [
          {
            text: t('reasons.3.links.faq_text'),
            url: 'web/wv/faqs/slug/tips_for_selling',
          },
        ],
      },
      {
        id: 4,
        text: t('reasons.4.text'),
        description: t('reasons.4.description'),
        links: [
          {
            text: t('reasons.4.links.faq_text'),
            url: 'web/wv/faqs/slug/meet_rude',
          },
        ],
      },
      {
        id: 9,
        text: t('reasons.9.text'),
        description: t('reasons.9.description'),
        links: [
          {
            text: t('reasons.9.links.faq_text_1'),
            url: 'faqs/15',
          },
          {
            text: t('reasons.9.links.faq_text_2'),
            url: 'faqs/203',
          },
          {
            text: t('reasons.9.links.faq_text_3'),
            url: 'faqs/202',
          },
        ],
        additionalDescription: t('reasons.9.additional_description'),
        additionalLinks: [
          {
            text: t('reasons.9.additional_links.faq_text_1'),
            url: 'web/wv/feedbacks/new?category_id=1&present=top',
          },
        ],
      },
      {
        id: 10,
        text: t('reasons.10.text'),
        description: t('reasons.10.description'),
        links: [
          {
            text: t('reasons.10.links.setting_text'),
            url: 'settings',
          },
        ],
      },
      {
        id: 5,
        text: t('reasons.5.text'),
        description: t('reasons.5.description'),
        links: [
          {
            text: t('reasons.5.links.faq_text_1'),
            url: 'faqs/171',
            onlyKR: true,
          },
          {
            text: t('reasons.5.links.faq_text_2'),
            url: 'faqs/3',
            onlyKR: true,
          },
        ],
      },
      {
        id: 6,
        text: t('reasons.6.text'),
        description: t('reasons.6.description'),
        links: [],
      },
    ],
    JP: [
      {
        id: 2,
        text: t('reasons.2.text'),
        description: t('reasons.2.description'),
        links: [
          {
            text: t('reasons.2.links.search_text'),
            url: 'search',
          },
          {
            text: t('reasons.2.links.keywords_text'),
            url: 'keywords',
          },
        ],
      },
      {
        id: 3,
        text: t('reasons.3.text'),
        description: t('reasons.3.description'),
        links: [
          {
            text: t('reasons.3.links.faq_text'),
            url: 'web/wv/faqs/slug/tips_for_selling',
          },
        ],
      },
      {
        id: 7,
        text: t('reasons.7.text'),
        description: t('reasons.7.description'),
        links: [
          {
            text: t('reasons.7.links.faq_text_1'),
            url: 'web/wv/feedbacks/new?kind=customer_support',
          },
        ],
      },
      {
        id: 8,
        text: t('reasons.8.text'),
        description: t('reasons.8.description'),
        links: [
          {
            text: t('reasons.7.links.faq_text_1'),
            url: 'web/wv/feedbacks/new?kind=customer_support',
          },
        ],
      },
      {
        id: 4,
        text: t('reasons.4.text'),
        description: t('reasons.4.description'),
        links: [
          {
            text: t('reasons.4.links.faq_text'),
            url: 'web/wv/faqs/slug/meet_rude',
          },
        ],
      },
      {
        id: 9,
        text: t('reasons.9.text'),
        description: t('reasons.9.description'),
        links: [
          {
            text: t('reasons.9.links.faq_text_1'),
            url: 'faqs/15',
          },
          {
            text: t('reasons.9.links.faq_text_2'),
            url: 'faqs/102',
          },
          {
            text: t('reasons.9.links.faq_text_3'),
            url: 'faqs/101',
          },
        ],
        additionalDescription: t('reasons.9.additional_description'),
        additionalLinks: [
          {
            text: t('reasons.9.additional_links.faq_text_1'),
            url: 'web/wv/feedbacks/new?category_id=1&present=top',
          },
        ],
      },
      {
        id: 10,
        text: t('reasons.10.text'),
        description: t('reasons.10.description'),
        links: [
          {
            text: t('reasons.10.links.setting_text'),
            url: 'settings',
          },
        ],
      },
      {
        id: 6,
        text: t('reasons.6.text'),
        description: t('reasons.6.description'),
        links: [],
      },
    ],
    US: [
      {
        id: 1,
        text: t('reasons.1.text'),
        description: t('reasons.1.description'),
        links: [],
      },
      {
        id: 2,
        text: t('reasons.2.text'),
        description: t('reasons.2.description'),
        links: [
          {
            text: t('reasons.2.links.search_text'),
            url: 'search',
          },
          {
            text: t('reasons.2.links.keywords_text'),
            url: 'keywords',
          },
        ],
      },
      {
        id: 3,
        text: t('reasons.3.text'),
        description: t('reasons.3.description'),
        links: [
          {
            text: t('reasons.3.links.faq_text'),
            url: 'web/wv/faqs/slug/tips_for_selling',
          },
        ],
      },
      {
        id: 4,
        text: t('reasons.4.text'),
        description: t('reasons.4.description'),
        links: [
          {
            text: t('reasons.4.links.faq_text'),
            url: 'web/wv/faqs/slug/meet_rude',
          },
        ],
      },
      {
        id: 9,
        text: t('reasons.9.text'),
        description: t('reasons.9.description'),
        links: [
          {
            text: t('reasons.9.links.faq_text_1'),
            url: 'faqs/15',
          },
          {
            text: t('reasons.9.links.faq_text_2'),
            url: 'faqs/203',
          },
          {
            text: t('reasons.9.links.faq_text_3'),
            url: 'faqs/202',
          },
        ],
        additionalDescription: t('reasons.9.additional_description'),
        additionalLinks: [
          {
            text: t('reasons.9.additional_links.faq_text_1'),
            url: 'web/wv/feedbacks/new?category_id=1&present=top',
          },
        ],
      },
      {
        id: 10,
        text: t('reasons.10.text'),
        description: t('reasons.10.description'),
        links: [
          {
            text: t('reasons.10.links.setting_text'),
            url: 'settings',
          },
        ],
      },
      {
        id: 5,
        text: t('reasons.5.text'),
        description: t('reasons.5.description'),
        links: [
          {
            text: t('reasons.5.links.faq_text_1'),
            url: 'faqs/171',
            onlyKR: true,
          },
          {
            text: t('reasons.5.links.faq_text_2'),
            url: 'faqs/3',
            onlyKR: true,
          },
        ],
      },
      {
        id: 6,
        text: t('reasons.6.text'),
        description: t('reasons.6.description'),
        links: [],
      },
    ],
  }

  return reasons[country]
}

export default useReasons
