import { useCallback } from 'react'

import { bridge } from '@src/bridge'
import {
  IS_DESKTOP,
  USER_AUTH_TOKEN,
  APP_USER_AGENT,
  APP_LOCALE,
  APP_COUNTRY,
  USER_ID,
  USER_PROFILE,
  USER_NICKNAME,
  USER_REGION_NAME2,
  USER_REGION_NAME3,
} from '@src/constants/config'
import { Info } from '@src/types/common'

const mockData = {
  app: {
    deviceIdentity: '',
    userAgent: APP_USER_AGENT,
    locale: APP_LOCALE,
    appHost: '',
    country: APP_COUNTRY as 'GB' | 'KR' | 'CA' | 'JP',
  },
  user: {
    authToken: USER_AUTH_TOKEN,
    id: Number(USER_ID),
    profileImage: USER_PROFILE,
    nickname: USER_NICKNAME,
    region: {
      name2: USER_REGION_NAME2,
      name3: USER_REGION_NAME3,
    },
  },
}

export const useInfo = () => {
  const getInfo = useCallback(() => {
    if (IS_DESKTOP) {
      return new Promise<Info>((resolve) => {
        setTimeout(() => resolve(mockData), 0)
      })
    }

    return Promise.all([bridge.info.app(), bridge.info.user()]).then(([app, user]) => ({
      app,
      user,
    }))
  }, [])

  return { getInfo }
}
