import { atom, selector } from 'recoil'

type LinkType = {
  text: string
  url: string
  onlyKR?: boolean
}

type ReasonType = {
  id: number
  text: string
  description: string
  links?: LinkType[]
  additionalDescription?: string
  additionalLinks?: LinkType[]
}

export const selectedReasonState = atom<ReasonType>({
  key: 'selectedReasonState',
  default: {
    id: 0,
    text: '',
    description: '',
  },
})

export const selectedReasonTextState = atom({
  key: 'selectedReasonTextState',
  default: '',
})

export const selectedReasonDescriptionSelector = selector({
  key: 'selectedReasonDescriptionSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.description
  },
})

export const selectedReasonLinkSelector = selector({
  key: 'selectedReasonLinkSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.links
  },
})

export const selectedReasonAdditionalDescriptionSelector = selector({
  key: 'selectedReasonAdditionalDescriptionSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.additionalDescription
  },
})

export const selectedReasonAdditionalLinkSelector = selector({
  key: 'selectedReasonAdditionalLinkSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.additionalLinks
  },
})

export const isEtcReasonSelector = selector({
  key: 'isEtcReasonSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.id === 6
  },
})

export const hasReasonState = atom({
  key: 'hasReasonState',
  default: false,
})

export const hasSelectedReasonSelector = selector({
  key: 'hasSelectedReasonSelector',
  get: ({ get }) => {
    const selectedReason = get(selectedReasonState)
    return selectedReason?.id !== 0
  },
})
