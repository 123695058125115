/* eslint-disable no-warning-comments */

import { ScreenHelmet } from '@karrotframe/navigator'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import FetchLoading from '@src/components/FetchLoading'
import DestroyableSection from '@src/components/Page/DestroyableSection'
import DestroySection from '@src/components/Page/DestroySection'
import ReasonSection from '@src/components/Page/ReasonSection'
import Wrap from '@src/components/Wrap'
import useDestroyable from '@src/hooks/useDestroyable'
import { hasReasonState } from '@src/store'

const Main = () => {
  const { t } = useTranslation()
  const hasReason = useRecoilValue(hasReasonState)
  const { loading, data, hasAdCash, hasBizProfile, hasDaangnPay } = useDestroyable()

  if (loading) {
    return (
      <>
        <ScreenHelmet title={t('title')} />
        <Wrap>
          <FetchLoading color={'#00000040'} />
        </Wrap>
      </>
    )
  }

  return (
    <>
      <ScreenHelmet title={t('title')} />
      <Wrap>
        <AnimatePresence>
          {data?.status?.code === 'ok' ? (
            hasReason ? (
              <DestroySection />
            ) : (
              <ReasonSection />
            )
          ) : (
            <DestroyableSection hasAdCash={hasAdCash} hasBizProfile={hasBizProfile} hasDaangnPay={hasDaangnPay} />
          )}
        </AnimatePresence>
      </Wrap>
    </>
  )
}

export default Main
