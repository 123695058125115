import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setApisBaseUrl } from '@src/apis'
import { useInfo } from '@src/hooks/useInfo'
import { storage } from '@src/utils/storage'

const useInit = () => {
  const [initState, setInitState] = useState<boolean>(false)
  const { i18n } = useTranslation()
  const { getInfo } = useInfo()

  useEffect(() => {
    getInfo()
      .then(({ app, user }) => {
        if (!app) {
          throw new Error(`bridge.app is not working, app: ${JSON.stringify(app)}`)
        }

        if (!user) {
          throw new Error(`bridge.user is not working, app: ${JSON.stringify(user)}`)
        }

        const { locale, country, userAgent } = app
        const { authToken, id, profileImage, nickname, region } = user

        i18n.changeLanguage(locale)
        storage.setAuthToken(authToken)
        storage.setUserAgent(userAgent)
        storage.setAcceptLanguage(locale)
        storage.setUserInfo({ id, profileImage, nickname, region })
        storage.setCountry(country)
        setApisBaseUrl(country.toLowerCase())
        setInitState(true)
      })
      .catch((error) => {
        Sentry.captureException(error)
        setInitState(false)
      })
  }, [getInfo, i18n])

  return { initState }
}

export default useInit
