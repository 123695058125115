import * as Sentry from '@sentry/react'

import { APP_ENV } from '@src/constants/config'

// eslint-disable-next-line no-restricted-imports
import packageJson from '../package.json'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: APP_ENV,
  release: `service-turorial-webview@${packageJson.version}`,
})

export default Sentry
