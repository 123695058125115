import en from '@src/locales/en.json'
import en_CA from '@src/locales/en_CA.json'
import en_GB from '@src/locales/en_GB.json'
import ja from '@src/locales/ja.json'
import ko_KR from '@src/locales/ko_KR.json'

const resources = {
  'ko-KR': ko_KR,
  'en-CA': en_CA,
  'en-GB': en_GB,
  'en-US': en,
  'ja-JP': ja,
  en,
  ja,
}

export default resources
