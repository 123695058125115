import { Global, css } from '@emotion/react'
import { vars } from '@seed-design/design-token'
import { useEffect } from 'react'
import VConsole from 'vconsole'

import useInit from '@src/hooks/useInit'
import Router from '@src/router'

import '@seed-design/stylesheet/global.css'

import '@karrotframe/navigator/index.css'

function App() {
  const { initState } = useInit()

  useEffect(() => {
    let vConsole: any = null
    if (process.env.REACT_APP_ENV !== 'production') {
      vConsole = new VConsole({ theme: 'dark' })
    }
    return () => {
      if (!vConsole) return
      vConsole.destroy()
    }
  }, [])

  return (
    <>
      {initState && <Router />}
      <Global
        styles={css`
          body {
            background-color: ${vars.$semantic.color.paperDefault};
            color: ${vars.$scale.color.gray900};
          }

          #root > div {
            --kf_navigator_backgroundColor: ${vars.$semantic.color.paperDefault};
            --kf_navigator_navbar-iconColor: ${vars.$scale.color.gray900};
            --kf_navigator_navbar-borderColor: ${vars.$semantic.color.divider3};
            --kf_navigator_navbar-center-textColor: ${vars.$scale.color.gray900};
          }
        `}
      />
    </>
  )
}

export default App
