import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import BottomArrow from '@src/assets/icons/BottomArrow'
import { bridge } from '@src/bridge'
import Button from '@src/components/Button'
import Description from '@src/components/Description'
import ReasonAdditionalDescription from '@src/components/ReasonAdditionalDescription'
import ReasonDescription from '@src/components/ReasonDescription'
import Title from '@src/components/Title'
import useClose from '@src/hooks/useClose'
import useReasons from '@src/hooks/useReasons'
import useShowEvent from '@src/hooks/useShowEvent'
import {
  hasReasonState,
  hasSelectedReasonSelector,
  isEtcReasonSelector,
  selectedReasonState,
  selectedReasonTextState,
} from '@src/store'
import { storage } from '@src/utils/storage'

const ReasonSection = () => {
  const { t } = useTranslation()
  const { nickname } = storage.getUserInfo()
  const close = useClose()
  const reasons = useReasons()
  const setHasReason = useSetRecoilState(hasReasonState)
  const [selectdReason, setSelectdReason] = useRecoilState(selectedReasonState)
  const hasSelectedReason = useRecoilValue(hasSelectedReasonSelector)
  const setSelectedReasonText = useSetRecoilState(selectedReasonTextState)
  const isEtc = useRecoilValue(isEtcReasonSelector)
  const [input, setInput] = useState<string>('')

  useShowEvent({
    screenName: 'withdraw_reason',
  })

  const handleSelectChange = (e: { target: { value: string | ((currVal: string) => string) } }) => {
    const selected = reasons?.filter((reason) => +e.target.value === reason.id)
    setSelectdReason(selected[0])
  }

  const handleInputChange = (e: any) => {
    setInput(e.target.value)
  }

  const handleSubmit = () => {
    bridge.analytics.log({
      name: 'client_clicked_identity_withdraw_reason_button_v1',
      target: 'KARROT',
    })

    if (selectdReason?.id === 6 && input.length <= 0) {
      return false
    }
    setSelectedReasonText(selectdReason?.id === 6 ? input : selectdReason?.text)
    setHasReason(true)
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
      <section>
        <header>
          <Title>
            <Trans i18nKey="reason_title" values={{ nickname: nickname }} />
          </Title>
        </header>
        <Description>{t('reason_description')}</Description>
      </section>
      <section>
        <header>
          <Title>
            <Trans i18nKey="reason_subtitle_1" values={{ nickname: nickname }} />
          </Title>
        </header>
        <SelectWrapper>
          <ReasonSelect onChange={handleSelectChange}>
            <option value="0">{t('reason_default_select_text')}</option>
            {reasons?.map(({ id, text }) => (
              <option value={id} key={id}>
                {text}
              </option>
            ))}
          </ReasonSelect>
          <BottomArrow />
        </SelectWrapper>
        {isEtc && (
          <ReasonInput
            type="text"
            placeholder={t('reason_default_input_placeholder')}
            value={input}
            onChange={handleInputChange}
          />
        )}
      </section>
      {hasSelectedReason && (
        <section>
          <ReasonDescription />
          {selectdReason?.id === 9 && <ReasonAdditionalDescription />}
          <ButtonSection>
            <CancelButton
              onClick={() => {
                bridge.analytics.log({
                  name: 'client_clicked_identity_withdraw_confirm_button_v1',
                  target: 'KARROT',
                  params: {
                    button: 'cancel',
                  },
                })
                close()
              }}>
              {t('reason_button_cancel')}
            </CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={selectdReason?.id === 6 && input.length <= 0}>
              {t('reason_button_ok')}
            </SubmitButton>
          </ButtonSection>
        </section>
      )}
    </motion.div>
  )
}

export default ReasonSection

const ReasonSelect = styled.select`
  display: block;
  width: 100%;
  padding: 12px 16px;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 150%;
  color: ${vars.$scale.color.gray900};
  background: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${vars.$scale.color.gray400};
  margin-top: 16px;
  margin-bottom: 16px;

  &:disabled {
    color: ${vars.$scale.color.gray900};
  }

  &:focus {
    outline: none;
  }
`

const ButtonSection = styled.section`
  display: flex;
  margin-top: 24px;
`

const CancelButton = styled(Button)`
  margin-right: 8px;
  color: ${vars.$scale.color.gray900};
  border: 1px solid ${vars.$scale.color.gray300};
`

const SubmitButton = styled(Button)`
  color: ${vars.$static.color.white};
  background: ${vars.$scale.color.carrot500};
  border: 1px solid ${vars.$scale.color.carrot500};

  &:disabled {
    background: ${vars.$scale.color.gray300};
    border: 1px solid ${vars.$scale.color.gray300};
  }
`

const SelectWrapper = styled.div`
  position: relative;
`

const ReasonInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px 16px;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 150%;
  color: ${vars.$scale.color.gray900};
  background: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${vars.$scale.color.gray400};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${vars.$scale.color.gray500};
  }
  :-ms-input-placeholder {
    color: ${vars.$scale.color.gray500};
  }
`
