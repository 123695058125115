import { Navigator, Screen } from '@karrotframe/navigator'

import { bridge } from '@src/bridge'
import useClose from '@src/hooks/useClose'
import { useEnvironment } from '@src/hooks/useEnvironment'
import MainPage from '@src/pages'

const Router = () => {
  const environment = useEnvironment()
  const close = useClose()

  return (
    <Navigator
      theme={environment}
      onClose={() => {
        bridge.analytics.log({
          name: 'client_clicked_identity_withdraw_confirm_button_v1',
          target: 'KARROT',
          params: {
            button: 'close',
          },
        })
        close()
      }}>
      <Screen path="/users/me/destroy" component={MainPage} />
    </Navigator>
  )
}

export default Router
