import { useMemo } from 'react'

import { bridge } from '@src/bridge'

export const useEnvironment = () => {
  const envrionment = useMemo(() => {
    const isAndroid = /Android/i.test(navigator.userAgent)
    const environment = bridge.environment

    if (environment === 'Web') {
      return isAndroid ? 'Android' : 'Cupertino'
    }

    return environment
  }, [])

  return envrionment
}
