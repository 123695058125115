import { useEffect, useState } from 'react'

import { userAPI } from '@src/apis'

const useDestroyable = () => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [hasAdCash, setHasAdCash] = useState(false)
  const [hasBizProfile, setHasBizProfile] = useState(false)
  const [hasDaangnPay, setHasDaangnPay] = useState(false)

  const fetchDestroyable = async () => {
    try {
      const response = await userAPI.getDestroyable()
      setData(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchDestroyable()
  }, [])

  useEffect(() => {
    if (data?.destroyable) {
      setHasAdCash(data?.destroyable?.find(({ kind }: any) => kind === 'ad_cash'))
      setHasBizProfile(data?.destroyable?.find(({ kind }: any) => kind === 'biz_profile'))
      setHasDaangnPay(data?.destroyable?.find(({ kind }: any) => kind === 'daangn_pay'))
    } else {
      setHasAdCash(false)
      setHasBizProfile(false)
      setHasDaangnPay(false)
    }
  }, [data])

  useEffect(() => {
    // 다른 페이지 이동 후 돌아오면 갱신하기
    const onVisibilityChange = () => !document.hidden && fetchDestroyable()
    document.addEventListener('visibilitychange', onVisibilityChange, false)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, loading, hasAdCash, hasBizProfile, hasDaangnPay }
}

export default useDestroyable
