import React from 'react'

const RightArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43433 6.43427C9.74675 6.12185 10.2533 6.12185 10.5657 6.43427L15.5657 11.4343C15.8781 11.7467 15.8781 12.2532 15.5657 12.5656L10.5657 17.5656C10.2533 17.8781 9.74675 17.8781 9.43433 17.5656C9.12191 17.2532 9.12191 16.7467 9.43433 16.4343L13.8686 12L9.43433 7.56564C9.12191 7.25322 9.12191 6.74669 9.43433 6.43427Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default RightArrow
