import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

const Button = styled.button`
  flex: 1;
  padding: 11.5px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;
  border-radius: 6px;
  background: ${vars.$semantic.color.paperDefault};
`

export default Button
