import Request from '@apis/Request'

export default class UserAPI extends Request {
  constructor() {
    super()
    this.setUserAgentHeader()
    this.setTokenHeader()
    this.setAcceptLanguage()
  }

  getItem(userId: number) {
    const url = `/api/v23/users/${userId}.json`
    return this.req.get(url)
  }

  getDestroyable() {
    const url = `/api/v23/users/me/destroyable.json`
    return this.req.get(url)
  }

  destroy(reason: string) {
    const url = `/api/v23/users/me/destroy_with_reason.json`
    return this.req.put(url, { status_reason: reason })
  }
}
