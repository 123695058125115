import { CircularProgress } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { memo } from 'react'

interface Props {
  color?: string
}

const FetchLoading = ({ color = '#868e96' }: Props) => {
  return (
    <Wrap>
      <Base>
        <CircularProgress color={color} size={24} />
      </Base>
    </Wrap>
  )
}

const Wrap = styled.div``

const Base = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default memo(FetchLoading)
