// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from '@src/locales/resources'

i18n.use(initReactI18next).init({
  resources,
  lng: 'ko-KR',
  // @ts-ignore
  fallbackLng: (nativeLanguageCode) => {
    const webLanguageCodes = {
      korean: 'ko-KR',
      japanese: 'ja-JP',
      canadianEnglish: 'en-CA',
      britishEnglish: 'en-GB',
      english: 'en-US',
    }
    if (/^ko(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.korean, webLanguageCodes.english]
    }
    if (/^ja(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.japanese, webLanguageCodes.english]
    }
    if (/^en(-|_)CA$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.canadianEnglish, webLanguageCodes.english]
    }
    if (/^en(-|_)GB$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.britishEnglish, webLanguageCodes.english]
    }
    if (/^en(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.english]
    }
    return [webLanguageCodes.english]
  },
  defaultNS: 'user_destroy',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
