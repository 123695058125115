interface UserInfoType {
  id: number
  profileImage: string
  nickname: string
  region: {
    name2: string
    name3: string
  }
}

class Storage {
  private authToken = ''
  private userAgent = ''
  private locale = ''
  private country = ''
  private user: UserInfoType = {
    id: 0,
    profileImage: '',
    nickname: '',
    region: {
      name2: '',
      name3: '',
    },
  }

  setAuthToken(token: string) {
    this.authToken = token
  }

  getAuthToken(): string {
    return this.authToken
  }

  setUserAgent(userAgent: string) {
    this.userAgent = userAgent
  }

  getUserAgent(): string {
    return this.userAgent
  }

  setAcceptLanguage(locale: string) {
    this.locale = locale
  }

  getAcceptLanguage(): string {
    return this.locale.replace('_', '-')
  }

  getLanguage(): string {
    return this.locale.split('_')[0]
  }

  setUserInfo(user: UserInfoType) {
    this.user = user
  }

  getUserInfo(): UserInfoType {
    return this.user
  }

  setCountry(country: string) {
    this.country = country
  }

  getCountry(): string {
    return this.country
  }
}

export const storage = new Storage()
