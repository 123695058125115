import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue } from 'recoil'

import { APP_SCHEME } from '@src/constants/config'
import { selectedReasonAdditionalLinkSelector, selectedReasonAdditionalDescriptionSelector } from '@src/store'
import { storage } from '@src/utils/storage'

const ReasonAdditonalDescription = () => {
  const country = storage.getCountry()
  const description = useRecoilValue(selectedReasonAdditionalDescriptionSelector)
  const links = useRecoilValue(selectedReasonAdditionalLinkSelector)

  return (
    <>
      <Text>{description}</Text>
      <LinkSection>
        {links?.map((link: any, index) => {
          if (link.onlyKR) {
            return (
              country === 'KR' && (
                <a href={`${APP_SCHEME}${link.url}?present=top`} key={index}>
                  {link.text}
                </a>
              )
            )
          } else {
            return (
              <a href={`${APP_SCHEME}${link.url}`} key={index}>
                {link.text}
              </a>
            )
          }
        })}
      </LinkSection>
    </>
  )
}

export default ReasonAdditonalDescription

const Text = styled.p`
  font-size: 16px;
  line-height: 150%;
  margin-top: 16px;

  .additional-text {
    display: block;
    margin-top: 16px;
  }
`

const LinkSection = styled.section`
  margin-top: 12px;

  a {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: ${vars.$scale.color.carrot500};
    padding: 4px 0;
  }
`
