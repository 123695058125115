import axios, { AxiosInstance } from 'axios'

import { storage } from '@src/utils/storage'

export default class Request {
  public req: AxiosInstance
  constructor() {
    this.req = axios.create({
      baseURL: '',
    })
  }

  public setBaseUrl(country: string) {
    const global: any = {
      gb: 'uk',
      us: 'ca',
      ca: 'ca',
      jp: 'jp',
    }

    if (country === 'kr') {
      this.req.defaults.baseURL = `${process.env.REACT_APP_KR_HOIAN_API_HOST}`
    } else {
      this.req.defaults.baseURL = `${process.env.REACT_APP_GLOBAL_HOIAN_API_HOST?.replace(
        '{country}',
        global[country]
      )}`
    }
  }

  public setTokenHeader() {
    this.req.interceptors.request.use(
      (config) => {
        config.headers['x-auth-token'] = storage.getAuthToken()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setUserAgentHeader() {
    this.req.interceptors.request.use(
      (config) => {
        config.headers['x-user-agent'] = storage.getUserAgent()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setAcceptLanguage() {
    this.req.interceptors.request.use(
      (config) => {
        config.headers['accept-language'] = storage.getAcceptLanguage()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }
}
