import { useEffect } from 'react'

import { bridge } from '@src/bridge'

export default function useShowEvent({ screenName }: { screenName: string }) {
  useEffect(() => {
    bridge.analytics.log({
      target: 'KARROT',
      name: 'client_shown_app_screen_v1',
      params: {
        service_name: 'common',
        screen_class_name: 'withdraw',
        screen_name: screenName,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
