import { bridge } from '@src/bridge'

export const IS_DESKTOP = bridge.environment === 'Web'
export const IS_ANDROID = bridge.environment === 'Android'

export const USER_ID = process.env.REACT_APP_USER_ID || ''
export const USER_AUTH_TOKEN = process.env.REACT_APP_USER_AUTH_TOKEN || ''
export const USER_PROFILE = process.env.REACT_APP_USER_PROFILE || ''
export const USER_NICKNAME = process.env.REACT_APP_USER_NICKNAME || ''
export const USER_REGION_NAME2 = process.env.REACT_APP_USER_REGION_NAME2 || ''
export const USER_REGION_NAME3 = process.env.REACT_APP_USER_REGION_NAME3 || ''

export const APP_LOCALE = process.env.REACT_APP_LOCALE || 'ko_KR'
export const APP_COUNTRY = process.env.REACT_APP_COUNTRY || 'KR'
export const APP_USER_AGENT = process.env.REACT_APP_USER_AGENT || ''

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''

export const APP_ENV = process.env.REACT_APP_ENV || 'development'
export const APP_SCHEME = process.env.REACT_APP_SCHEME || ''
export const APP_WEBVIEW_URL = process.env.REACT_APP_WEBVIEW_URL || ''
export const APP_PAY_WEBVIEW_URL = process.env.REACT_APP_PAY_WEBVIEW_URL || ''
