import styled from '@emotion/styled'

const Description = styled.p`
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 32px;
  margin-top: 12px;
`

export default Description
