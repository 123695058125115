import { useCallback } from 'react'

import { bridge } from '@src/bridge'
import { IS_DESKTOP } from '@src/constants/config'

const useClose = () => {
  const close = useCallback(() => {
    if (IS_DESKTOP) {
      alert('window close event trigger')
      return
    }

    bridge.router.close()
  }, [])

  return close
}

export default useClose
